import React from 'react'
import { Heading, Flex, Spacer, Box, Grid, GridItem } from '@chakra-ui/react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql, useStaticQuery } from 'gatsby'
import { FoerderungQuery } from '../../types/graphql-types'
import Img from 'gatsby-image'

const FoerderungPage = () => {
  const data = useStaticQuery<FoerderungQuery>(graphql`
    query Foerderung {
      eplr: file(relativePath: { eq: "foerderung/eplr.jpg" }) {
        childImageSharp {
          fixed(height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      eplr_qr: file(relativePath: { eq: "foerderung/qr_eplr.jpg" }) {
        childImageSharp {
          fixed(height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      leader: file(relativePath: { eq: "foerderung/leader_logo.jpg" }) {
        childImageSharp {
          fixed(height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      hessen: file(relativePath: { eq: "foerderung/hessen_logo.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      eu: file(relativePath: { eq: "foerderung/eu_logo.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Förderung" />

      <div style={{ backgroundColor: '#fff', height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Heading>Einrichtung eines Ferienhofs</Heading>
        <Grid templateColums="30% 70%" gap={10}>
          <GridItem rowSpan={1} colSpan={1} colStart={2} rowStart={1} h={10}>
            <Heading size="md" style={{ color: '#1661AD' }}>
              Gefördert durch
            </Heading>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} rowStart={2} colStart={1}>
            <a href="http://ec.europa.eu/agriculture/index_de.htm">
              <Img fixed={data.eu.childImageSharp.fixed} />
            </a>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} rowStart={2} colStart={2}>
            Europäischer Landwirtschaftsfonds für die Entwicklung des ländlichen Raums: Hier investiert Europa in
            ländlichen Gebiete
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} rowStart={3} colStart={2}>
            <Heading size="md" style={{ color: '#1661AD' }}>
              mitfinanziert durch
            </Heading>
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} rowStart={4} colStart={1}>
            <Img fixed={data.hessen.childImageSharp.fixed} />
          </GridItem>
          <GridItem rowSpan={1} colSpan={1} rowStart={4} colStart={2}>
            das Land Hessen im Rahmen des Entwicklungsplans für den ländlichen Raum des Landes Hessen 2014-2020
          </GridItem>
        </Grid>
        <div style={{ width: 'calc(100%-20px)', backgroundColor: '#1661AD', color: '#fff', padding: '10px' }}>
          mit dem Ziel der Erreichung einer ausgewognenen räumlichen Entwicklung der ländlichen Wirtschaft und der
          ländlichen Gemeinschaften, einschließlich der Schaffung und des Erhalts von Arbeitsplätzen
        </div>
        <Flex style={{ height: '70px', width: '100%', marginTop: '20px' }}>
          <a href="https://www.eler.hessen.de">
            <Img fixed={data.eplr_qr.childImageSharp.fixed} />
          </a>
          <div style={{ height: '100%', fontSize: '12px', width: '250px' }}>
            Dieser Code führt Sie direkt zum ELER-Programm des Hessischen Ministeriums für Umwelt, Klimaschutz,
            Landwirtschaft und Verbraucherschutz.
          </div>
          <Spacer />
          <Img fixed={data.leader.childImageSharp.fixed} />
          <Img fixed={data.eplr.childImageSharp.fixed} />
        </Flex>
      </div>
    </Layout>
  )
}

export default FoerderungPage
